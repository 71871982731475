/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
/*
import React from 'react';
import ArwesThemeProvider from './src/components/ArwesThemeProvider/ArwesThemeProvider'

export const WrapRootElement = ({ element }) => {
  return (
    <ArwesThemeProvider>
        {element}
      </ArwesThemeProvider>
  );
};
*/
/*
import React from 'react';
export { wrapRootElement } from './src/components/ArwesThemeProvider/ArwesThemeProvider'
import Layout from './src/components/Layout/'
export const wrapPageElement = data => <Layout {...data} />;*/
//import React from 'react';
//import { WrapRootElement } from './src/components/WrapRootElement';
import ReactDOM from 'react-dom'
export function replaceHydrateFunction(){
return (element, container, callback) => {
	ReactDOM.render(element, container, callback)
}
}
//import ThemeProvider from 'react-jss'
//import ArwesThemeProvider from './src/components/ArwesThemeProvider/ArwesThemeProvider';
//import { useTheme } from "./src/use-theme";

//const theme = useTheme();


//const wrapRootElement = WrapRootElement;
//export {wrapRootElement}