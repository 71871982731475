// import { createTheme } from "./create-theme";

import {
  createThemeBreakpoints,
  createThemeColor,
  createThemeMultiplier,
  createThemeUnit,
} from "arwes";

// export const theme = createTheme({
//   outline: 3,
// });

// const primaryMain = createThemeColor(["hsl(210 100% 40%)"]);
const primaryMain = createThemeColor([
  "hsl(285, 80%, 97.25%)",
  "hsl(285, 68.63%, 90.00%)",
  "hsl(285, 67.41%, 73.53%)",
  "hsl(285, 68.07%, 53.33%)",
  "hsl(285, 68.14%, 44.31%)",
  "hsl(285, 68.59%, 37.45%)",
  "hsl(285, 69.03%, 30.39%)",
  "hsl(285, 68.18%, 25.88%)",
  "hsl(285, 67.92%, 20.78%)",
  "hsl(285, 69.23%, 15.29%)",
  "hsl(285, 69.23%, 10%)",
  "hsl(285, 69.23%, 7%)",
  "hsl(285, 70%, 4%)",
]);

const baseSpacing = 10;
export const theme = Object.freeze({
  // REMs unit.
  space: createThemeUnit((index) => `${index * 0.25}rem`),
  animation : {
    duration: 100,
	time:250,
	stagger: 50,
	easing: 'ease-out',
	spring: {
	  tension: 170,
	  friction: 26
	}
  },
  // Pixels as number unit.
  spacing: createThemeMultiplier((index) => index * baseSpacing),

  // Extends the Tailwind theme responsive breakpoints.
  breakpoints: createThemeBreakpoints([
    { key: "3sm", value: "375px" },
    { key: "2sm", value: "410px" },
    { key: "sm", value: "640px" },
    { key: "md", value: "768px" },
    { key: "lg", value: "1024px" },
    { key: "xl", value: "1280px" },
    { key: "2xl", value: "1536px" },
    { key: "3xl", value: "1980px" },
  ]),
  color: {
	background:{
		dark: "rgb(48, 25, 52)"
	},
	primary:{
		dark:  "rgb(0, 255, 255)"
	},
    alpha:  0.0,
	tertiary:{
		main: "rgb(255, 165, 0)"
	}
  },
  palette: {
    // background: settings.background,
    background: {
      body: () => "#090012",
      appbar: () => "hsla(285, 100%, 75%, 0.1)",
      paper: createThemeColor([
        "hsl(285, 14.29%, 97.25%)",
        "hsl(285, 21.57%, 90.00%)",
        "hsl(285, 21.48%, 73.53%)",
        "hsl(285, 25.21%, 53.33%)",
        "hsl(285, 27.43%, 44.31%)",
        "hsl(285, 24.61%, 37.45%)",
        "hsl(285, 22.58%, 30.39%)",
        "hsl(285, 19.70%, 25.88%)",
        "hsl(285, 20.75%, 20.78%)",
        "hsl(285, 20.51%, 15.29%)",
        "hsl(285, 20.51%, 10%)",
        "hsl(285, 20.51%, 7%)",
        "hsl(285, 20%, 4%)",
      ]),
    },
    primary: {
      low: createThemeColor([
        "hsl(285, 14.29%, 97.25%)",
        "hsl(285, 21.57%, 90.00%)",
        "hsl(285, 21.48%, 73.53%)",
        "hsl(285, 25.21%, 53.33%)",
        "hsl(285, 27.43%, 44.31%)",
        "hsl(285, 24.61%, 37.45%)",
        "hsl(285, 22.58%, 30.39%)",
        "hsl(285, 19.70%, 25.88%)",
        "hsl(285, 20.75%, 20.78%)",
        "hsl(285, 20.51%, 15.29%)",
        "hsl(285, 20.51%, 10%)",
        "hsl(285, 20.51%, 7%)",
        "hsl(285, 20%, 4%)",
      ]),
      main: createThemeColor([
        "hsl(285, 71.43%, 97.25%)",
        "hsl(285, 68.63%, 90.00%)",
        "hsl(285, 67.41%, 73.53%)",
        "hsl(285, 68.07%, 53.33%)",
        "hsl(285, 68.14%, 44.31%)",
        "hsl(285, 68.59%, 37.45%)",
        "hsl(285, 69.03%, 30.39%)",
        "hsl(285, 68.18%, 25.88%)",
        "hsl(285, 67.92%, 20.78%)",
        "hsl(285, 69.23%, 15.29%)",
        "hsl(285, 69.23%, 10%)",
        "hsl(285, 69.23%, 7%)",
        "hsl(285, 70%, 4%)",
      ]),
      high: createThemeColor([
        "hsl(285, 85.71%, 97.25%)",
        "hsl(285, 88.24%, 90.00%)",
        "hsl(285, 89.63%, 73.53%)",
        "hsl(285, 89.92%, 53.33%)",
        "hsl(285, 90.27%, 44.31%)",
        "hsl(285, 89.53%, 37.45%)",
        "hsl(285, 89.68%, 30.39%)",
        "hsl(285, 89.39%, 25.88%)",
        "hsl(285, 90.57%, 20.78%)",
        "hsl(285, 89.74%, 15.29%)",
        "hsl(285, 89.74%, 10.29%)",
        "hsl(285, 89.74%, 7.29%)",
        "hsl(285, 90%, 4%)",
      ]),
    },
    secondary: {
      low: createThemeColor([
        "hsl(60, 20.00%, 96.08%)",
        "hsl(60, 21.13%, 86.08%)",
        "hsl(60, 24.44%, 64.71%)",
        "hsl(60, 26.23%, 47.84%)",
        "hsl(60, 24.30%, 41.96%)",
        "hsl(60, 22.22%, 35.29%)",
        "hsl(60, 20.55%, 28.63%)",
        "hsl(60, 20.33%, 24.12%)",
        "hsl(60, 19.19%, 19.41%)",
        "hsl(60, 19.44%, 14.12%)",
        "hsl(60, 20%, 10%)",
        "hsl(60, 20%, 7%)",
        "hsl(60, 20%, 4%)",
      ]),
      main: createThemeColor([
        "hsl(60, 70.00%, 96.08%)",
        "hsl(60, 69.01%, 86.08%)",
        "hsl(60, 67.78%, 64.71%)",
        "hsl(60, 68.85%, 47.84%)",
        "hsl(60, 68.22%, 41.96%)",
        "hsl(60, 68.89%, 35.29%)",
        "hsl(60, 68.49%, 28.63%)",
        "hsl(60, 69.11%, 24.12%)",
        "hsl(60, 67.68%, 19.41%)",
        "hsl(60, 69.44%, 14.12%)",
        "hsl(60, 70%, 10%)",
        "hsl(60, 70%, 7%)",
        "hsl(60, 70%, 4%)",
      ]),
      high: createThemeColor([
        "hsl(60, 90.00%, 96.08%)",
        "hsl(60, 88.73%, 86.08%)",
        "hsl(60, 90.00%, 64.71%)",
        "hsl(60, 90.16%, 47.84%)",
        "hsl(60, 89.72%, 41.96%)",
        "hsl(60, 90.00%, 35.29%)",
        "hsl(60, 90.41%, 28.63%)",
        "hsl(60, 90.24%, 24.12%)",
        "hsl(60, 89.90%, 19.41%)",
        "hsl(60, 88.89%, 14.12%)",
        "hsl(60, 90%, 10%)",
        "hsl(60, 90%, 7%)",
        "hsl(60, 90%, 4%)",
      ]),
    },
    neutral: createThemeColor([
      "hsl(0, 0.00%, 98.04%)",
      "hsl(0, 0.00%, 93.33%)",
      "hsl(0, 0.00%, 83.53%)",
      "hsl(0, 0.00%, 74.12%)",
      "hsl(0, 0.00%, 64.71%)",
      "hsl(0, 0.00%, 54.51%)",
      "hsl(0, 0.00%, 44.31%)",
      "hsl(0, 0.00%, 37.25%)",
      "hsl(0, 0.00%, 29.80%)",
      "hsl(0, 0.00%, 21.96%)",
      "hsl(0, 0.00%, 12.16%)",
      "hsl(0, 0.00%, 8%)",
      "hsl(0, 0.00%, 5%)",
    ]),
    error: createThemeColor([
      "hsl(10, 100%, 60%)",
      // "hsl(10, 75.00%, 98.43%)",
      // "hsl(10, 71.42%, 94.50%)",
      // "hsl(10, 69.01%, 86.07%)",
      // "hsl(10, 69.64%, 78.03%)",
      // "hsl(10, 69.23%, 69.41%)",
      // "hsl(10, 69.37%, 59.01%)",
      // "hsl(10, 49.99%, 47.84%)",
      // "hsl(10, 49.75%, 40.58%)",
      // "hsl(10, 50.30%, 32.35%)",
      // "hsl(10, 49.59%, 24.11%)",
      // "hsl(10, 50%, 18%)",
      // "hsl(10, 50%, 14%)",
      // "hsl(10, 50%, 9%)",
    ]),
    success: createThemeColor(["hsl(200deg 100% 60%)"]),
    warning: createThemeColor(["hsl(40deg 100% 60%)"]),
    info: createThemeColor(["hsl(170deg 100% 60%)"]),
  },

  /** @deprecated */
  font: {
    body: {
      fontFamily:
        'ui-monospace, SFMono-Regular, Menlo, "Roboto Mono", monospace',
      color: "silver",
    },
  },
  /** @deprecated */
  fontFamily: {
    header: [
      "var(--app-font-family-header)",
      '"Segoe UI Web (West European)"',
      '"Segoe UI"',
      "-apple-system",
      "BlinkMacSystemFont",
      "Roboto",
      '"Helvetica Neue"',
      "sans-serif",
    ],
    body: [
      "var(--app-font-family-body)",
      '"Segoe UI Web (West European)"',
      '"Segoe UI"',
      "-apple-system",
      "BlinkMacSystemFont",
      "Roboto",
      '"Helvetica Neue"',
      "sans-serif",
    ],
    cta: [
      "var(--app-font-family-cta)",
      '"Segoe UI Web (West European)"',
      '"Segoe UI"',
      "-apple-system",
      "BlinkMacSystemFont",
      "Roboto",
      '"Helvetica Neue"',
      "sans-serif",
    ],
    code: [
      "var(--app-font-family-code)",
      "JetBrains Mono",
      "Menlo",
      "Monaco",
      "Consolas",
      "Courier New",
      "monospace",
    ],
  },
  components: {
    // header: { background: "#055" },
    // footer: { background: "#055" },
    header: {
      background: primaryMain(10, { alpha: 0.7 }),
      backdropFilter: "blur(10px)",
    },
    footer: {
      background: primaryMain(10, { alpha: 0.7 }),
      backdropFilter: "blur(10px)",
    },
    paper: {},
    card: {
      background: primaryMain(9, { alpha: 0.6 }),
      // backdropFilter: "blur(10px)",
    },
    // header: [{ background: "hsla(180, 100%, 75%, 0.2)" }],
    // footer: [{ background: "hsla(180, 100%, 75%, 0.2)" }],
  },
  zIndex: {
    header: 10,
    footer: 10,
  },
  typography: {
    h1: { fontSize: "1.4rem" },
    h2: { fontSize: "1.25rem" },
    h3: { fontSize: "1.125rem" },
    h4: { fontSize: "1rem" },
    h5: { fontSize: "0.9rem" },
    h6: { fontSize: "0.8rem" },
    code: {},
    subtitle1: {
      margin: "0px 0px 0.35em",
      // fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.75,
      letterSpacing: "0.00938em",
    },
    subtitle2: {
      margin: "0px 0px 0.35em",
      // fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.57,
      letterSpacing: "0.00714em",
    },
    body1: {
      margin: 0,
      // fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    body2: {
      margin: 0,
      // fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.43,
      letterSpacing: "0.01071em",
      color: "rgba(255, 255, 255, 0.4)",
    },
    caption: {
      margin: "0px 0px 0.35em",
      // fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
      letterSpacing: "0.03333em",
      display: "block",
    },
  },
});

export type ThemeColors = keyof (typeof theme)["palette"];
export type ThemeTypography = keyof (typeof theme)["typography"];
